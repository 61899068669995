import Footer from "./footer";
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Post2 from "./post2";
import { Button, Image, Spin, Modal , message} from 'antd';
import React from 'react';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Footer_menu from "./footer_menu";
import moment from 'moment-timezone';
import Nav2 from "./navbar2";
import { MediumCircleFilled } from "@ant-design/icons";
import { Breadcrumbs, Link, Typography } from "@mui/joy";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon,
    EmailIcon,
  } from 'react-share';
import Post_recommends from "./post_recomends";


const Post = () => {
  const navigate = useNavigate();
    // const [category_name,setCategory_name] = useState(null);
    // const Category_name = ()=>{
    //     if(product[0].categories == 1){
    //       setCategory_name('Недвижимость');
    //     }
    //     if(product[0].categories == 2){
    //         setCategory_name('Транспорт');
    //       }
    //    }


    const [visible, setVisible] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const params = useParams();
    const id = params.id;
    const [categories, setCategories] = useState(null);
    const [product, setProduct] = useState(null);
    const [product_cat, setProduct_cat] = useState(null);
    const [message1, setMessage1] = useState(null);
    const [room_id, setRoom_id] = useState(null);
    const [product_car_mark, setProduct_car_mark] = useState(null);
    const [product_car_model, setProduct_car_model] = useState(null);
    const [product_car_generation, setProduct_car_generation] = useState(null);
    const [product_car_serie, setProduct_car_serie] = useState(null);
    const [product_car_modification, setProduct_car_modification] = useState(null);
    const [product_car_equipment, setProduct_car_equipment] = useState(null);
    const [product_podcat, setProduct_podcat] = useState(null);
    const [product_buy_sell, setProduct_buy_sell] = useState(null);
    const [product_period, setProduct_period] = useState(null);
    const [product_categories2, setProduct_categories2] = useState(null);
    const [limit, setLimit] = useState(12);
    const [product_metro, setProduct_metro] = useState(null);

    const fetchProduct = async () => {
        const post = {
            id: id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/post'
        });
        if (data.data.status == 200) {
            setProduct(data.data.post);
            fetchProduct_cat(data.data.post[0].categories);
            fetchProduct_car_mark(data.data.post[0].mark);
            fetchProduct_car_model(data.data.post[0].models);
            fetchProduct_car_generation(data.data.post[0].generation);
            fetchProduct_car_serie(data.data.post[0].serie);
            fetchProduct_car_modification(data.data.post[0].modification);
            fetchProduct_car_equipment(data.data.post[0].equipment);
            fetchProduct_podcat(data.data.post[0].podcategories);
            fetchProduct_buy_sell(data.data.post[0].buy_sell);
            fetchProduct_period(data.data.post[0].period);
            fetchProduct_categories2(data.data.post[0].categories2);
            fetchProduct_metro(data.data.post[0].metro);
            fetchProduct_userid(data.data.post[0].userid);
            fetchRecommends(data.data.post[0].categories);
            
        } else {
            
            
        }
    }
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                fetchProducts(data.data.users5[0].id);
            setUsertoken(data.data.users5[0].id);
            Logged(data.data.users5[0].id);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                
                if (data.data.status == 200) {
                    
                    setUsertoken(data.data.users5[0].id);
                    fetchProducts(data.data.users5[0].id);
                    setUsertoken(data.data.users5[0].id);
                    Logged(data.data.users5[0].id);
                }
        }
            } 
      }
    const [favorited, setFavorited] = useState(false);
    const fetchProducts = async (user_id) => {
        const params = {
            ad_id: ad_id,
            user_id: user_id,

        };

        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/favorite'
        });
        if (data.data.status == 200) {
            setFavorited(true);
            
        } else {
            
            setFavorited(false);
            
        }
    }
    const fetchProduct_cat = async (categories) => {
        const post = {
            id: categories,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_cat'
        });
        if (data.data.status == 200) {
            setProduct_cat(data.data.id_cat);
            
        } else {
            
            
        }
    }
    const fetchProduct_car_mark = async (mark) => {
        const post = {
            id: mark,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_car_mark'
        });
        if (data.data.status == 200) {
            setProduct_car_mark(data.data.id_car_mark);
            
        } else {
            
            
        }
    }
    const fetchProduct_car_model = async (name) => {
        const post = {
            id: name,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_car_model'
        });
        if (data.data.status == 200) {
            setProduct_car_model(data.data.id_car_model);
            
        } else {
            
            
        }
    }
    const fetchProduct_car_generation = async (id_car_generation) => {
        const post = {
            id: id_car_generation,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_car_generation'
        });
        if (data.data.status == 200) {
            setProduct_car_generation(data.data.id_car_generation);
            
        } else {
            
            
        }
    }
    const fetchProduct_car_serie = async (id_car_serie) => {
        const post = {
            id: id_car_serie,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_car_serie'
        });
        if (data.data.status == 200) {
            setProduct_car_serie(data.data.id_car_serie);
            
        } else {
            
            
        }
    }
    const fetchProduct_car_modification = async (id_car_modification) => {
        const post = {
            id: id_car_modification,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_car_modification'
        });
        if (data.data.status == 200) {
            setProduct_car_modification(data.data.id_car_modification);
            
        } else {
            
            
        }
    }
    const fetchProduct_car_equipment = async (id_car_equipment) => {
        const post = {
            id: id_car_equipment,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_car_equipment'
        });
        if (data.data.status == 200) {
            setProduct_car_equipment(data.data.id_car_equipment);
            
        } else {
            
            
        }
    }
    const fetchProduct_podcat = async (podcategories) => {
        const post = {
            id: podcategories,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/podcat'
        });
        if (data.data.status == 200) {
            setProduct_podcat(data.data.podcat);
            
        } else {
            
            
        }
    }
    const fetchProduct_buy_sell = async (buy_sell) => {
        const post = {
            id: buy_sell,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/buy_sell'
        });
        if (data.data.status == 200) {
            setProduct_buy_sell(data.data.buy_sell);
            
        } else {
            
            
        }
    }
    const fetchProduct_period = async (period) => {
        const post = {
            id: period,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/period'
        });
        if (data.data.status == 200) {
            setProduct_period(data.data.period);
            
        } else {
            
            
        }
    }
    const fetchProduct_categories2 = async (categories2) => {
        const post = {
            id: categories2,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/categories2'
        });
        if (data.data.status == 200) {
            setProduct_categories2(data.data.categories2);
            
        } else {
            
            
        }
    }






    const [recommends, setRecommends] = useState(null);
    const limit_add = () => {
        fetchRecommends(categories);
    }
    const fetchRecommends = async (id) => {
        setCategories(id);
        const params = {
            id: limit,
            categories: id
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/rekom'
        });
        if (data.data.status == 200) {
            setRecommends(data.data.post);
            setLimit(limit + 12);
            
        } else {
            
            
        }
    }
    const ad_id = params.id;
    // const user_id = localStorage.getItem('token');

    const addfavorites = async () => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
    
            };
    
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL + '/favorites'
            });
    
            
            if (data != null) {
                if (data.status == 200) {
                    
                    fetchProducts(usertoken);
                } else {
                    alert('Error')
                }
            }
        }else{
            warning();
        }
        
    }
    const delfavorites = async () => {
        const params = {
            ad_id: ad_id,
            user_id: usertoken,
        };
        const data = await axios({
            method: 'delete',
            params: params,
            url: API_URL + '/delete_favorites'
        });
        
        if (data != null) {
            if (data.status == 200) {
                
                fetchProducts(usertoken);
            } else {
                alert('Error')
            }
        }
    }
    const [logged, setLogged] = useState(false);
    // const local = localStorage.getItem('token');
    const Logged = (local) => {
        if (local != null) {
            setLogged(true);
        }
    }
    const chat_if = async () => {
        if (logged == true) {
            const params = {
                ad_id: ad_id,
                user1_id: usertoken,
                user2_id: product[0].userid,
            };
            const data = await axios({
                method: 'get',
                params: params,
                url: API_URL + '/chat_if'
            });
            
            if (data.data.status == 200){
                setRoom_id(data.data.chat_if[0].id);
                chat(data.data.chat_if[0].id);
                
            } else {
                

                chats();
                // window.location.href="/mainsms/";
                
            }
        } else {
            warning();
        }

    }


    const chats = async () => {
        const params = {
            ad_id: ad_id,
            user1_id: usertoken,
            user2_id: product[0].userid,
        };
        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL + '/chats'
        });
        
        if (data != null) {
            if (data.status == 200) {
                
                error();
                localStorage.setItem('chat', message1);
                setTimeout(chat_if, 2000);
            } else {
                alert('Error')
            }
        }
    }

    const chat = async (id) => {
        const params = {
            ad_id: ad_id,
            user_id: usertoken,
            message: message1,
            room_id: id,
            user_id2: product[0].userid
        };
        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL + '/chat'
        });
        
        if (data != null) {
            if (data.status == 200) {
                
                success();
                setMessage1(null);
                document.getElementById('searchInput').value = '';
            } else {
                chat_if();
            }
        }
    }
    const [number, setNumber] = useState(null);
    
    const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Сообщение отправлено!',
        });
      };
    const success2= (str) => {
        messageApi.open({
          type: 'success',
          content: (' Скопирован'),
        });
      };
    
    
    const [products1, setProducts1] = useState(null);
    const fetchProducts1 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL + '/getmetro2'
        });
        if (data.data.status == 200) {
            setProducts1(data.data.getmetro);
            
        } else {
            
        }
    }
    const fetchProduct_metro = async (metro) => {
        const post = {
            id: metro,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/getmetro2'
        });
        if (data.data.status == 200) {
            setProduct_metro(data.data.getmetro2);
            
        } else {
            
            
        }
    }
    const [product_users, setProduct_users] = useState(null);
    const fetchProduct_userid = async (userid) => {
        const post = {
            id: userid,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users1'
        });
        if (data.data.status == 200) {
            setProduct_users(data.data.users1);
            
        } else {
            
            
        }
    }
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (id) => {
      setIsModalOpen(true);
      
    };
    const [share_id, setShare_id] = useState(null);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const showModal2 = (ad_id) => {
        setShare_id(ad_id);
      setIsModalOpen2(true);
      
    };
    const handleOk = () => {
        chat_if();
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
      setIsModalOpen2(false);
    };
      const error = () => {
        messageApi.open({
          content:  <Spin /> ,
        });
      };
      const warning = () => {
        messageApi.open({
          type: 'warning',
          content: 'Авторизуйтесь!',
        });
    }

    const [banner,setBanner] = useState(null);
    const fetchBanner = async () => {
        const data = await axios({
            method:'get',
            url:API_URL+'/reklama'
        });
        if(data.data.status == 200){
            setBanner(data.data.reklama);
            
        }else{
            
        }
    }
    const [products_floor, setProducts_floor] = useState();
    const fetchProducts_floor = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory3'
    });
    if (data.data.status == 200){
      setProducts_floor(data.data.getcategory3);
      
    } else {
      
    }
    }
    const [products_click, setProducts_click] = useState(null);
const fetchProducts_click = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getclick'
});

if (data.data.status == 200){
  setProducts_click(data.data.getview);
  
} else {
  
}
}
const share =()=>{
    if(navigator.share){
        navigator.share({
            title:'title',
            text:'text',
            url:'https://google.com'
        })
    }
}


const shareData = async () => {
    if (navigator.share) {
      try {
        const data = await navigator.share({
          title: 'Объявление с сайта Birimdik',
          text: 'Бесплатные рекламы на Birimdik.kg!',
          url: 'post/' + ad_id, // Убедитесь, что ad_id правильно определён
        });
        console.log('Успешно поделились', data);
      } catch (error) {
        alert('Ошибка при попытке поделиться: ' + error.message); // Показать сообщение об ошибке
      }
    } else {
      alert('Ошибка! Этот браузер не поддерживает Web Share API.');
    }
  };
  

//   const shareData = {
//     title: "MDN",
//     text: "Learn web development on MDN!",
//     url: "https://developer.mozilla.org",
//   };
  
//   const btn = document.querySelector("button");
//   const resultPara = document.querySelector(".result");
  
//   // Share must be triggered by "user activation"
//   btn.addEventListener("click", async () => {
//     try {
//       await navigator.share(shareData);
//       resultPara.textContent = "MDN shared successfully";
//     } catch (err) {
//       resultPara.textContent = `Error: ${err}`;
//     }
//   });


    useEffect(() => {
        // Category_name();
        useridtoken();
        fetchProducts_click();
        fetchProducts_floor();
        fetchProduct();
        fetchProducts1();
        fetchBanner();
    }, [])


   
    const formatPrice = (price) => {
        // Проверка на число и форматирование с разделителем тысяч
        const priceNumber = parseFloat(price); // Преобразуем строку в число, если это необходимо
        if (isNaN(priceNumber)) {
          return '—'; // Если цена не число, возвращаем дефолтное значение
        }
        return priceNumber.toLocaleString('ru-RU'); // Используем 'ru-RU' для форматирования с точкой как разделителем
      };
      
      if (!product ) {
        return <div>Загрузка...</div>; // Покажите индикатор загрузки или текст
      }

    return (
        <>
        
        
        {<div style={{zIndex:'5'}}>{contextHolder}</div>}
        <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} cancelText='Отмена' onCancel={handleCancel}>
      <textarea id="searchInput" onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
      </Modal>

      <Modal  title="Поделиться" open={isModalOpen2}  cancelText='Отмена' onCancel={handleCancel}>
      <WhatsappShareButton url={'https://birimdik.kg/post/'+share_id}  title={'Москва жарнамалары'} description={<img alt="Chat on WhatsApp" src="WhatsAppButtonGreenLarge.png" />} ><WhatsappIcon size={42} style={{margin:'5px'}} round /></WhatsappShareButton>
    <TelegramShareButton url={'https://birimdik.kg/post/'+share_id} title={'Москва жарнамалары'} description={<img alt="Chat on WhatsApp" src="WhatsAppButtonGreenLarge.png" />}><TelegramIcon size={42} style={{margin:'5px'}} round /></TelegramShareButton>
    <EmailShareButton url={'https://birimdik.kg/post/'+share_id} title={'Москва жарнамалары'} subject={'email'}  body={'des'}><EmailIcon size={42} style={{margin:'5px'}} round /></EmailShareButton>
    <TwitterShareButton url={'https://birimdik.kg/post/'+share_id} title={'Москва жарнамалары'}><TwitterIcon size={42} style={{margin:'5px'}} round /></TwitterShareButton>
    <FacebookShareButton url={'https://birimdik.kg/post/'+share_id} title={'Москва жарнамалары'} quote={'des'}><FacebookIcon size={42} style={{margin:'5px'}} round /></FacebookShareButton>
      </Modal>
           
            <div className="container"  >
           
                <div style={{ width: '100%' }} className='row '>
                    
                    <div className='rek_left' style={{ width: '15%', marginLeft: '-50px' }}>
                    {banner != null ? <>
          {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
            <a href='/#L1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads + filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
            <a href='/#L2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
            <a href='/#L3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
            <a href='/#L4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
            <a href='/#L5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        </> : <></>}
        {banner != null ? <>
          {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
            <a href='/#L1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
            <a href='/#L2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
            <a href='/#L3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
            <a href='/#L4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
            <a href='/#L5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        </> : <></>}</div>
                    <div style={{padding:'20px'}} className='maincon col-md-12 col-lg-9'>
                    <div className="row d-none d-md-block">
                    <Nav2/>
                    </div>
                    
                        <div  className='bg-light  mainrow row'>
                       
                            <div  className='row'>
                           
                            </div>
                           
                            <div class="container bg-light postcontainer">
                                <div class="row">
                                
                                    {product != null || product != undefined || product?.length > 0 ?
                                        <>
                                            <div className="col-md-12">
                                                
                                            </div>
                                            <div  class="col-md-12 col-lg-12 col-12">
                                            <div className="row">
                                            </div>
                                                <div className="row">
                                                <div>
                                                
                                                </div>

                                                  
                                                    <div  className="container d-none d-md-block">
                                                        <div class='row ' >
                                                        
                                                        
                                                        <Breadcrumbs  aria-label="breadcrumbs">
                                                            {product_cat  != null ? <><Link href={product_cat[0].link}  color="neutral" >{product_cat[0].name}</Link></>:<></>}
                                                            {product_podcat  != null ? <><Link  color="neutral" href={product_podcat[0].link}>{product_podcat[0].name}</Link></>:<></>}
                                                            {product_buy_sell  != null ? <><Link  color="neutral" href={product_buy_sell[0].link}>{product_buy_sell[0].name}</Link></>:<></>}
                                                            {product_period  != null ? <><Link  color="neutral" href={product_period[0].link}>{product_period[0].name}</Link> </>:<></>}
                                                            {product_car_mark  != null ? <><Link  color="neutral" href={product_car_mark[0].link}>{product_car_mark[0].name}</Link></>:<></>} 
                                                            {product_car_model  != null ? <><Link  color="neutral" href={product_car_model[0].link}>{product_car_model[0].name}</Link> </>:<></>}  
                                                            
                                                           
                                                        </Breadcrumbs>
                                                            
                                                                
                                                           
                                                            
                                                           
                                                             
                                                            

                                                            <div  className="col-md-6 col-12 col-lg-5 ">
                                                                
<div  style={{alignContent:'center'}} className="row ">
<div style={{width:'100%',height:'350px'}} id="carouselExampleIndicators2" class="carousel " data-ride="carousel">
  <ol class="carousel-indicators2">
    <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
    <li data-target="#carouselExampleIndicators2" data-slide-to="6"></li>
  </ol>
  <div style={{width:'100%',height:'350px'}}  class="carousel-inner">
    <div style={{width:'100%',height:'350px'}}  class="carousel-item active">
    <div class="carousel-caption ">
        <p className="mt-3">1-7</p>
      </div>
      <img  style={{width:'100%',height:'350px'}}   src={product[0].images != null ? (uploads + product[0].images) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
    <div style={{width:'100%',height:'350px'}}  class="carousel-item">
        <div class="carousel-caption ">
            <p>2-7</p>
        </div>
      <img style={{width:'100%',height:'350px'}}  src={product[0].image1 != null ? (uploads + product[0].image1) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
    <div style={{width:'100%',height:'350px'}}  class="carousel-item">
    <div class="carousel-caption ">
            <p>3-7</p>
        </div>
      <img style={{width:'100%',height:'350px'}}  src={product[0].image2 != null ? (uploads + product[0].image2) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
    <div style={{width:'100%',height:'350px'}}  class="carousel-item">
    <div class="carousel-caption ">
            <p>4-7</p>
        </div>
      <img style={{width:'100%',height:'350px'}}  src={product[0].image3 != null ? (uploads + product[0].image3) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
    <div style={{width:'100%',height:'350px'}}  class="carousel-item">
    <div class="carousel-caption ">
            <p>5-7</p>
        </div>
      <img style={{width:'100%',height:'350px'}}  src={product[0].image4 != null ? (uploads + product[0].image4) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
    <div style={{width:'100%',height:'350px'}}  class="carousel-item">
    <div class="carousel-caption ">
            <p>6-7</p>
        </div>
      <img style={{width:'100%',height:'350px'}}  src={product[0].image5 != null ? (uploads + product[0].image5) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
    <div style={{width:'100%',height:'350px'}}  class="carousel-item">
    <div class="carousel-caption ">
            <p>7-7</p>
        </div>
      <img style={{width:'100%',height:'350px'}}  src={product[0].image6 != null ? (uploads + product[0].image6) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
  </div>
  
  <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators2" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators2" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </button>
</div>
                                                              
                                                                </div>
                                                                    
                                                                
                                                                <div style={{marginLeft:'0px'}} className="col-md-12 navimgmob">
                                                                   
                                                                  
                                                                </div>
                                                            </div>
                                                            
                                                                   
                                                            <div class="col-md-6 col-lg-3 ">
                                                         
                                                            {product_metro ? <><p style={{ fontSize: '16px', marginTop:'8%',height:'10px'}}>Метро :<MediumCircleFilled style={{color : '#' + product_metro[0].color, fontSize:'22px'}} /> <b>{product_metro[0].Station} </b></p></> : <></>} 
                                                                {product_buy_sell ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}><b>{product_buy_sell[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_mark ? <><div><p> Марка : <b>{product_car_mark[0].name}</b>  </p></div></> : <></>}
                                                                {product_car_model ? <><div  style={{ marginTop: '-15px', fontSize: '16px' }}><p>Модель : <b>{product_car_model[0].name}</b> </p></div></> : <></>}
                                                                {product_car_generation ?
                                                                    <>
                                                                        <div style={{ marginTop: '-15px', fontSize: '16px' }}>Поколение : <b>{product_car_generation[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_serie ?
                                                                    <>
                                                                        <div style={{ fontSize: '16px', marginTop: '1px' }}><label >Тип кузова : <b>{product_car_serie[0].name}</b></label></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_modification ?
                                                                    <>
                                                                        <div style={{ fontSize: '16px' }}><label >Модификация : <b>{product_car_modification[0].name}</b></label></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_equipment ?
                                                                    <>
                                                                        <div style={{ fontSize: '16px', marginTop: '1px' }}><label>Оборудования : <b>{product_car_equipment[0].name}</b></label></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product.year ? <> <div><p style={{ fontSize: '16px' }}>Год выпуска : <b>{product[0].year}</b></p></div></> : <></>}
                                                                {product_categories2 ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}> <b>{product_categories2[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {products_floor != null ? <>
                                                                    {products_floor.filter(floor => floor.id == product[0].floor).map(filteredFloor => (
                                                                <a >
                                                                    
                                                                    {filteredFloor.name ? <><div style={{ marginTop: '-1px', fontSize: '16px' }}>Этаж : <b>{filteredFloor.name}</b></div></> :
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}></div>
                                                                    </>}
                                                                </a>
                                                                
                                                                ))}
                                                                
                                                                </> : <>
                                                                
                                                                </>}
                                                                {product.square != null || product[0].square != undefined ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Площадь : <b>{product[0].square} </b>( м² )</div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product.acres != null || product[0].acres != undefined ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Площадь :<b>{product[0].acres}</b> ( Соток )</div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product.rooms ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Комната : <b>{product[0].rooms}</b></div>
                                                                    </>

                                                                    : <></>
                                                                }
                                                                {product_period ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Срок : <b>{product_period[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product[0].date1 ? <> <p style={{ fontSize: '16px',marginTop:'10%',height:'10px' }}>Опубликовано : <b>{moment.tz(product[0].date1, "Europe/Moscow").fromNow()}</b></p></> : <></>}
                                                                {products_click != null ? <>
                                                                    {products_click.filter(view => view.ad_id == product[0].id).map(filteredView => (
                                                                <a>
                                                                    {filteredView.click != null ? <><label style={{fontSize:'16px',height:'10px',marginTop:'8%'}} >Посмотрели : <b>{filteredView.click}</b> </label></> :
                                                                    <>
                                                                    </>}
                                                                </a>
                                                                
                                                                ))}
                                                                
                                                                </> : <>
                                                                </>}
                                                             
                                                                {/* {product[0].custom_atribute != '[]' ? 
                                                 <>
                                                 <div style={{marginTop:'-1px',fontSize:'16px'}}>{JSON.parse(product[0].custom_atribute)[0]},{JSON.parse(product[0].custom_atribute)[1]},{JSON.parse(product[0].custom_atribute)[2]},{JSON.parse(product[0].custom_atribute)[3]},{JSON.parse(product[0].custom_atribute)[4]}</div>
                                                 </>
                                                 :<>
                                                    
                                                 </>    
                                                 } */}


                                                            </div>

                                                            <div className="col-lg-4 d-none d-md-block">
                                                                <div className="row">
                                                                <div class="card">
                                                                            <div class="modal-header ">
                                                                                <h1 class="modal-title "><div><p className="txt7"> {product[0].price != 0  ? `${formatPrice(product[0].price)} ${product[0].currency}`  : 'Договорная'}<label style={{ marginLeft: '10px', fontSize: '14px' }}></label></p></div></h1>
                                                                            </div>
                                                                            
                                                                                <form  style={{marginTop:'-25px'}}>
                                                                                    <div  class="mb-3">
                                                                                        {product_users != null || product_users != undefined || product_users?.length > 0 ? 
                                                                                        <>
                                                                                        {product_users[0].avatar ? <><a href={'/user/' + product_users[0].uid}><img  style={{width:'40px',height:'40px',borderRadius:'50%'}} src={uploads + product_users[0].avatar}></img></a></> : <><img style={{width:'40px',height:'40px',borderRadius:'50%'}} src={uploads + 'nouser.png'}></img></>}
                                                                                        {product_users[0].name ? <> <label style={{ fontSize: '16px' }}> {product_users[0].name}</label></> : <></>}
                                                                                        <textarea id="searchInput" style={{marginTop:'10px',maxHeight:'90px'}} onChange={(e) => { setMessage1(e.target.value) }} placeholder="Написать сообщение" class="form-control" ></textarea>
                                                                                        </> : 
                                                                                        <>loading</>}
                                                                                    </div>
                                                                                </form>
                                                                               
                                                                           
                                                                            <div class="modal-footer">
                                                                                <button onClick={chat_if} type="button" class="btn btn-primary"> Отправить</button>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{marginTop:'20px' }} className="col-12  desc">
                                                                                    <b style={{ fontSize: '16px',marginTop:'20px'}}>Описание</b>
                                                                                    <p className="card" style={{ fontSize: '18px', fontFamily: 'unset' }}>{product[0].descriptions}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                    
                                                        </div>
                                                    </div>

                                                    <div  className="col-md-12 col-lg-7 navimg">
                                                    <div style={{marginLeft:'0px'}} className="col-md-12 mt-5">
                                                                    <nav style={{ height: '100%', marginLeft: '-30px' }} class="nav-scroller__items navscroll">
                                                                        <Image.PreviewGroup>
                                                                        <a data-slide-to="0" data-target="#carouselExampleIndicators2" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            
                                                                            <div style={{pointerEvents:'none'}}>
                                                                            
                                                                            <Image height='80px' width='80px' src={product[0].images != null ? (uploads + product[0].images) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                        </div>
                                                                        </a>
                                                                            <a data-slide-to="1" data-target="#carouselExampleIndicators2" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            
                                                                                <div style={{pointerEvents:'none'}}>
                                                                                
                                                                                <Image height='80px' width='80px' src={product[0].image1 != null ? (uploads + product[0].image1) : (uploads + 'no_image.jpg')} />
                                                                                </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="2" data-target="#carouselExampleIndicators2" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image2 != null ? (uploads + product[0].image2) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="3" data-target="#carouselExampleIndicators2" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image3 != null ? (uploads + product[0].image3) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="4" data-target="#carouselExampleIndicators2" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image4 != null ? (uploads + product[0].image4) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="5" data-target="#carouselExampleIndicators2" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image5 != null ? (uploads + product[0].image5) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="6" data-target="#carouselExampleIndicators2" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                                <div style={{pointerEvents:'none'}}>
                                                                                    <Image height='80px' width='80px' src={product[0].image6 != null ? (uploads + product[0].image6) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>

                                                                        </Image.PreviewGroup>
                                                                    </nav>
                                                                </div>
                                                       
                                                    </div>
                                                  

                                                    <div   className="row d-none d-md-flex">
                                                        <div  class="col-6">
                                                            {favorited ?
                                                                <>
                                                                <a onClick={delfavorites} style={{ border: "1px solid green", fontSize: '14px', width: '100%' }} class="btn btn-light btbt"><i class=" fa-solid fa-heart text-danger "></i> Удалить из избранные</a>
                                                                </>
                                                                : <>
                                                                    <a onClick={addfavorites} style={{ border: "1px solid green", fontSize: '14px', width: '100%', height: '40px' }} class="btn btn-light btbt"><i class=" fa-solid fa-heart text-secondary "></i> В избранное</a>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="col-6">
                                                            <a type="button" onClick={() => showModal2(product[0].id)} style={{ float: 'right', fontSize: '14px', height: '40px', width: '100%' }} class="btn btn-warning ml-4 "> 
                                                               Поделиться
                                                            </a>
                                                        </div>
                                                        <div className="col-6 mt-2">
                                                            <a onClick={() => showModal(product[0].id)} type="button"  style={{ fontSize: '14px', color: 'white', width: '100%' }} class="btn btn-primary "><i class="fa-solid fa-comment-sms"></i> Написать</a>
                                                        </div>
                                                        <div  className="col-6 mt-2">
                                                            <a id="item-to-copy" href={'tel:'+product[0].numbers} style={{ float: "right", color: 'white', fontSize: '14px' }} class="btn btn-success form-control "><i class="fa-solid fa-phone"></i> {product[0].numbers}</a>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="col-md-12 d-none d-md-block" >
                                                        <div className="">
                                                            <b>Полезная информация!</b>
                                                            <label style={{ fontSize: '16px' }}>Избегайте мошенничества, проверяйте перед оплатой. Будьте внимательны. Этот сайт не несет ответственности за достоверность публикуемых объявлений.</label>
                                                        </div>
                                                    </div>



                                                    <div  className="container d-block d-md-none">
                                                        <div class='row ' >
                                                        
                                                         
                                                                <Breadcrumbs   aria-label="breadcrumbs">
                                                            {product_cat  != null ? <><Link  fontSize='12px' href={product_cat[0].link}  color="neutral" >{product_cat[0].name}</Link></>:<></>}
                                                            {product_podcat  != null ? <><Link  fontSize='12px'  color="neutral" href={product_podcat[0].link}>{product_podcat[0].name}</Link></>:<></>}
                                                            {product_car_mark  != null ? <><Link  fontSize='12px' color="neutral" href={product_car_mark[0].link}>{product_car_mark[0].name}</Link></>:<></>} 
                                                            {product_car_model  != null ? <><Link  fontSize='12px' color="neutral" href={product_car_model[0].link}>{product_car_model[0].name}</Link> </>:<></>}  
                                                            
                                                             </Breadcrumbs>
                                                             <div style={{width:'100%',height:'420px'}} id="carouselExampleIndicators" class="carousel " data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
  </ol>
  <div style={{width:'100%',height:'420px'}}  class="carousel-inner">
    <div style={{width:'100%',height:'420px'}}  class="carousel-item active">
    <Image    preview={{
    }}  style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].images != null ? (uploads + product[0].images) : (uploads + 'no_image.jpg')} />
      {/* <img  style={{width:'100%',height:'420px'}}   src={product[0].images != null ? (uploads + product[0].images) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/> */}
    </div>
    <div style={{width:'100%',height:'420px'}}  class="carousel-item">
    <Image   style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].image1 != null ? (uploads + product[0].image1) : (uploads + 'no_image.jpg')} />
      {/* <img style={{width:'100%',height:'420px'}}  src={product[0].image1 != null ? (uploads + product[0].image1) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/> */}
    </div>
    <div style={{width:'100%',height:'420px'}}  class="carousel-item">
    <Image   style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].image2 != null ? (uploads + product[0].image2) : (uploads + 'no_image.jpg')} />
      {/* <img style={{width:'100%',height:'420px'}}  src={product[0].image2 != null ? (uploads + product[0].image2) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/> */}
    </div>
    <div style={{width:'100%',height:'420px'}}  class="carousel-item">
    <Image   style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].image3 != null ? (uploads + product[0].image3) : (uploads + 'no_image.jpg')} />
      {/* <img style={{width:'100%',height:'420px'}}  src={product[0].image3 != null ? (uploads + product[0].image3) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/> */}
    </div>
    <div style={{width:'100%',height:'420px'}}  class="carousel-item">
    <Image   style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].image4 != null ? (uploads + product[0].image4) : (uploads + 'no_image.jpg')} />
      {/* <img style={{width:'100%',height:'420px'}}  src={product[0].image4 != null ? (uploads + product[0].image4) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/> */}
    </div>
    <div style={{width:'100%',height:'420px'}}  class="carousel-item">
    <Image   style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].image5 != null ? (uploads + product[0].image5) : (uploads + 'no_image.jpg')} />
      {/* <img style={{width:'100%',height:'420px'}}  src={product[0].image5 != null ? (uploads + product[0].image5) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/> */}
    </div>
    <div style={{width:'100%',height:'420px'}}  class="carousel-item">
    <Image   style={{width:'100%',height:'420px'}} class="d-block w-100" alt={product[0].categories} src={product[0].image6 != null ? (uploads + product[0].image6) : (uploads + 'no_image.jpg')} />
      <img style={{width:'100%',height:'420px'}}  src={product[0].image6 != null ? (uploads + product[0].image6) : (uploads + 'no_image.jpg')} class="d-block w-100" alt="..."/>
    </div>
  </div>
  
  <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </button>
</div>

                                                            <div  className="col-md-6 col-12 col-lg-4 ">
                                                     
                                                                    <div style={{marginLeft:'0px'}} className="col-md-12 ">
                                                                    <nav style={{ height: '100%', marginLeft: '-30px' }} class="nav-scroller__items navscroll">
                                                                        <Image.PreviewGroup >
                                                                        <a data-slide-to="0" data-target="#carouselExampleIndicators" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            
                                                                            <div  style={{pointerEvents:'none'}} >
                                                                            
                                                                            <Image  height='80px' width='80px' src={product[0].images != null ? (uploads + product[0].images) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                        </div>
                                                                        </a>
                                                                            <a data-slide-to="1" data-target="#carouselExampleIndicators" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            
                                                                                <div style={{pointerEvents:'none'}}>
                                                                                
                                                                                <Image height='80px' width='80px' src={product[0].image1 != null ? (uploads + product[0].image1) : (uploads + 'no_image.jpg')} />
                                                                                </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="2" data-target="#carouselExampleIndicators" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image2 != null ? (uploads + product[0].image2) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="3" data-target="#carouselExampleIndicators"  class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image3 != null ? (uploads + product[0].image3) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="4" data-target="#carouselExampleIndicators" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image4 != null ? (uploads + product[0].image4) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="5" data-target="#carouselExampleIndicators" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                            <div style={{pointerEvents:'none'}}>
                                                                                <Image height='80px' width='80px' src={product[0].image5 != null ? (uploads + product[0].image5) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>
                                                                            <a data-slide-to="6" data-target="#carouselExampleIndicators" class="nav-scroller__item" style={{ width: '27%' }} ><div className=' text-center '>
                                                                                <div style={{pointerEvents:'none'}}>
                                                                                    <Image height='80px' width='80px' src={product[0].image6 != null ? (uploads + product[0].image6) : (uploads + 'no_image.jpg')} />
                                                                            </div>
                                                                            </div>
                                                                            </a>

                                                                        </Image.PreviewGroup>
                                                                    </nav>
                                                                </div>
                                                            </div>
                                                           
                                                             

                                                       
                                                            <div class="col-md-6 col-lg-3 ">
                                                              <div><p>Цена : <b style={{fontSize:'18px'}}> {product[0].price != 0  ? `${formatPrice(product[0].price)} ${product[0].currency}`  : 'Договорная'}</b>  </p></div>
                                                                {product_buy_sell ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}><b>{product_buy_sell[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_mark ? <><div><p> Марка : <b>{product_car_mark[0].name}</b>  </p></div></> : <></>}
                                                                {product_car_model ? <><div  style={{ marginTop: '-15px', fontSize: '16px' }}><p>Модель : <b>{product_car_model[0].name}</b> </p></div></> : <></>}
                                                                {product_car_generation ?
                                                                    <>
                                                                        <div style={{ marginTop: '-15px', fontSize: '16px' }}>Поколение : <b>{product_car_generation[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_serie ?
                                                                    <>
                                                                        <div style={{ fontSize: '16px', marginTop: '1px' }}><label >Тип кузова : <b>{product_car_serie[0].name}</b></label></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_modification ?
                                                                    <>
                                                                        <div style={{ fontSize: '16px' }}><label >Модификация : <b>{product_car_modification[0].name}</b></label></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_car_equipment ?
                                                                    <>
                                                                        <div style={{ fontSize: '16px', marginTop: '1px' }}><label>Оборудования : <b>{product_car_equipment[0].name}</b></label></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product.year ? <> <div><p style={{ fontSize: '16px' }}>Год выпуска : <b>{product[0].year}</b></p></div></> : <></>}
                                                                {product_categories2 ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}> <b>{product_categories2[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {products_floor != null ? <>
                                                                    {products_floor.filter(floor => floor.id == product[0].floor).map(filteredFloor => (
                                                                <a >
                                                                    
                                                                    {filteredFloor.name ? <><div style={{ marginTop: '-1px', fontSize: '16px' }}>Этаж : <b>{filteredFloor.name}</b></div></> :
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}></div>
                                                                    </>}
                                                                </a>
                                                                
                                                                ))}
                                                                
                                                                </> : <>
                                                                
                                                                </>}
                                                                {product.square != null || product[0].square != undefined ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Площадь : <b>{product[0].square} </b>( м² )</div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product.acres != null || product[0].acres != undefined ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Площадь :<b>{product[0].acres}</b> ( Соток )</div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product.rooms ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Комната : <b>{product[0].rooms}</b></div>
                                                                    </>

                                                                    : <></>
                                                                }
                                                                {product_period ?
                                                                    <>
                                                                        <div style={{ marginTop: '-1px', fontSize: '16px' }}>Срок : <b>{product_period[0].name}</b></div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                                {product_metro ? <><div><p style={{ fontSize: '16px', marginTop: '0px',height:'10px'}}>Метро : <b>{product_metro[0].Station} </b><MediumCircleFilled style={{color : '#' + product_metro[0].color, fontSize:'22px'}} /></p></div></> : <></>} 
                                                               
                                                                {product[0].date1 ? <> <div><p style={{ fontSize: '16px', marginTop: '0px',height:'10px' }}>Опубликовано : <b>{moment.tz(product[0].date1, "Europe/Moscow").fromNow()}</b></p></div></> : <></>}
                                                                {products_click != null ? <>
                                                                    {products_click.filter(view => view.ad_id == product[0].id).map(filteredView => (
                                                                <a>
                                                                    {filteredView.click != null ? <><label style={{fontSize:'16px',height:'10px'}} >Посмотрели : <b>{filteredView.click}</b> </label></> :
                                                                    <>
                                                                    </>}
                                                                </a>
                                                                
                                                                ))}
                                                                
                                                                </> : <>
                                                                </>}
                                                                {product[0].id ? <> <div><p style={{ fontSize: '16px', marginTop: '0px',height:'10px',color:'gray' }}>ID : {product[0].id}</p></div></> : <></>}
                                                              <label>Описание:</label>
                                                                <p className="card d-block d-md-none" style={{ fontSize: '18px', fontFamily: 'unset' }}>{product[0].descriptions}</p>
                                                


                                                            </div>

                                                            <div className="col-lg-5 d-none d-md-block">
                                                                <div className="row">
                                                                <div class="card">
                                                                            <div class="modal-header ">
                                                                                <h1 class="modal-title "><div><p className="txt7"> {product[0].price != 0  ? `${formatPrice(product[0].price)} ${product[0].currency}`  : 'Договорная'}<label style={{ marginLeft: '10px', fontSize: '14px' }}></label></p></div></h1>
                                                                            </div>
                                                                            
                                                                                <form  style={{marginTop:'-25px'}}>
                                                                                    <div  class="mb-3">
                                                                                        {product_users != null || product_users != undefined || product_users?.length > 0 ? 
                                                                                        <>
                                                                                        {product_users[0].avatar ? <><a href={'/user/' + product_users[0].uid}><img  style={{width:'40px',height:'40px',borderRadius:'50%'}} src={uploads + product_users[0].avatar}></img></a></> : <><img style={{width:'40px',height:'40px',borderRadius:'50%'}} src={uploads + 'nouser.png'}></img></>}
                                                                                        {product_users[0].name ? <> <label style={{ fontSize: '16px' }}> {product_users[0].name}</label></> : <></>}
                                                                                        <textarea id="searchInput" style={{marginTop:'10px',maxHeight:'90px'}} onChange={(e) => { setMessage1(e.target.value) }} placeholder="Написать сообщение" class="form-control" ></textarea>
                                                                                        </> : 
                                                                                        <>loading</>}
                                                                                    </div>
                                                                                </form>
                                                                               
                                                                           
                                                                            <div class="modal-footer">
                                                                                <button onClick={chat_if} type="button" class="btn btn-primary"> Отправить</button>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{marginTop:'20px' }} className="col-12  desc">
                                                                                    <b style={{ fontSize: '16px',marginTop:'20px'}}>Описание</b>
                                                                                    <p className="card" style={{ fontSize: '18px', fontFamily: 'unset' }}>{product[0].descriptions}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                    
                                                        </div>
                                                    </div>

                                                    
                                                  

                                                   

                                                    <div   className="row d-flex d-md-none">
                                                        <div  class="col-6">
                                                            {favorited ?
                                                                <>
                                                                <a onClick={delfavorites} style={{ border: "1px solid green", fontSize: '14px', width: '100%' }} class="btn btn-light btbt"><i class=" fa-solid fa-heart text-danger "></i> Удалить из избранные</a>
                                                                </>
                                                                : <>
                                                                    <a onClick={addfavorites} style={{ border: "1px solid green", fontSize: '14px', width: '100%', height: '40px' }} class="btn btn-light btbt"><i class=" fa-solid fa-heart text-secondary "></i> В избранное</a>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="col-6">
                                                        <a type="button" onClick={() => showModal2(product[0].id)} style={{ float: 'right', fontSize: '14px', height: '40px', width: '100%' }} class="btn btn-warning ml-4 "> 
                                                               Поделиться
                                                            </a>
                                                        </div>
                                                        <div className="col-6 mt-2">
                                                            <a onClick={() => showModal(product[0].id)} type="button"  style={{ fontSize: '14px', color: 'white', width: '100%' }} class="btn btn-primary "><i class="fa-solid fa-comment-sms"></i> Написать</a>
                                                        </div>
                                                        <div  className="col-6 mt-2">
                                                            <a id="item-to-copy" href={'tel:'+product[0].numbers} style={{ float: "right", color: 'white', fontSize: '14px' }} class="btn btn-success form-control "><i class="fa-solid fa-phone"></i> {product[0].numbers}</a>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 d-block  d-md-none" >
                                                        <div className="">
                                                            <b>Полезная информация!</b>
                                                            <label style={{ fontSize: '16px' }}>Избегайте мошенничества, проверяйте перед оплатой. Будьте внимательны. Этот сайт не несет ответственности за достоверность публикуемых объявлений.</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12  ">
                                                        <div class="row card">
                                                            <div className="row">
                                                                <div class="col-md-12  ">

                                                                </div>

                                                                <div className="col-md-6 mt-1">


                                                                </div>

                                                            </div>

                                                            <div class="col-md-12">
                                                                <h5>Рекомендации</h5>
                                                            </div>
                                                            <div className="row ml-1">
                                                                {recommends != null || recommends != undefined || recommends?.length > 0 ?
                                                                    <>
                                                                        {recommends.map((item) =>
                                                                            <div style={{padding:'2px'}} class="col-6 col-md-6 col-lg-3 mt-3 ">
                                                                                <Post_recommends product={item} />
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </>
                                                                    : <>Loading</>
                                                                }
                                                                <div className="col-md-12 mt-2">
                                                                    <Button onClick={limit_add} type="primary" block>
                                                                        Показать Ещё
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 ">

                                                    </div>

                                                </div>
                                            </div>
                                           


                                            <div className='row '>
<div className='col-md-4'></div>

    <div className='col-md-4'></div>
</div>
                                        </>
                                        : <>Loading</>
                                    }
                                </div>
                            </div>


                            <Footer />

                        </div>
                    </div>
                    <div className='rek_right' style={{ width: '15%', marginRight: '-50px' }} >
                    {banner != null ? <>
            {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => (
            <a href='/#R1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => (
            <a href='/#R2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => (
            <a href='/#R3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => (
            <a href='/#R4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => (
            <a href='/#R5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        </> : <></>}
        {banner != null ? <>
            {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => (
            <a href='/#R1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => (
            <a href='/#R2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => (
            <a href='/#R3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => (
            <a href='/#R4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => (
            <a href='/#R5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image.jpg'}></img></>}</a>
        ))}
        </> : <></>}</div>
                </div>




            </div>
            <div  className={footer_menu_class}>
                <Footer_menu />
            </div>



           
 <div style={{ height: '150px', marginTop: '200px', backgroundColor: 'white', opacity: '0.7' }} class="modal fade2" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                <div class="modal-body">
                    <div className="col-12">
                        <Spin style={{ fontSize: '24px', height: '300px' }} tip="Загрузка подождите или повторите попытку">

                        </Spin>
                    </div>
                </div>


            </div>


        </>




    );
}
export default Post;