// const firebaseConfig = {
//   apiKey: "AIzaSyD_bMzoAQaLhnI5PiNOQpEI7OHqU8LdwuQ",
//   authDomain: "birimdik-35d2b.firebaseapp.com",
//   projectId: "birimdik-35d2b",
//   storageBucket: "birimdik-35d2b.appspot.com",
//   messagingSenderId: "806199593777",
//   appId: "1:806199593777:web:6c1342f9b921814589fe10",
//   measurementId: "G-NYKENM6WXS"
// };
// const clientId = '';
// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// const analytics = app.analytics();
// export const provider = firebase.auth.GoogleAuthProvider;
// export const auth = app.auth();

// // Import the functions you need from the SDKs you need
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// import firebase from "firebase";
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB2ZcIBK4zEu99QIaGdR2h5u5KEdGxKNRE",
//   authDomain: "birimdik-3bd99.firebaseapp.com",
//   databaseURL: "https://birimdik-3bd99-default-rtdb.firebaseio.com",
//   projectId: "birimdik-3bd99",
//   storageBucket: "birimdik-3bd99.appspot.com",
//   messagingSenderId: "515516667880",
//   appId: "1:515516667880:web:35781071785d6f96ae365d",
//   measurementId: "G-VCP42Y43PW"
// };

// const clientId = '';
// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// const analytics = app.analytics();
// export const provider = firebase.auth.GoogleAuthProvider;
// export const auth = app.auth();


// // Import the functions you need from the SDKs you need
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// import firebase from "firebase";
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD_bMzoAQaLhnI5PiNOQpEI7OHqU8LdwuQ",
//   authDomain: "birimdik-35d2b.firebaseapp.com",
//   databaseURL: "https://birimdik-35d2b-default-rtdb.firebaseio.com",
//   projectId: "birimdik-35d2b",
//   storageBucket: "birimdik-35d2b.appspot.com",
//   messagingSenderId: "806199593777",
//   appId: "1:806199593777:web:6c1342f9b921814589fe10",
//   measurementId: "G-NYKENM6WXS"
// };
// const clientId = '';
// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// const analytics = app.analytics();
// export const provider = firebase.auth.GoogleAuthProvider;
// export const auth = app.auth();


// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD_bMzoAQaLhnI5PiNOQpEI7OHqU8LdwuQ",
//   authDomain: "birimdik-35d2b.firebaseapp.com",
//   databaseURL: "https://birimdik-35d2b-default-rtdb.firebaseio.com",
//   projectId: "birimdik-35d2b",
//   storageBucket: "birimdik-35d2b.appspot.com",
//   messagingSenderId: "806199593777",
//   appId: "1:806199593777:web:6c1342f9b921814589fe10",
//   measurementId: "G-NYKENM6WXS"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);



// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB2ZcIBK4zEu99QIaGdR2h5u5KEdGxKNRE",
//   authDomain: "birimdik-3bd99.firebaseapp.com",
//   databaseURL: "https://birimdik-3bd99-default-rtdb.firebaseio.com",
//   projectId: "birimdik-3bd99",
//   storageBucket: "birimdik-3bd99.appspot.com",
//   messagingSenderId: "515516667880",
//   appId: "1:515516667880:web:35781071785d6f96ae365d",
//   measurementId: "G-VCP42Y43PW"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// // Import the functions you need from the SDKs you need
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// import firebase from "firebase";
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD_bMzoAQaLhnI5PiNOQpEI7OHqU8LdwuQ",
//   authDomain: "birimdik-35d2b.firebaseapp.com",
//   projectId: "birimdik-35d2b",
//   storageBucket: "birimdik-35d2b.appspot.com",
//   messagingSenderId: "806199593777",
//   appId: "1:806199593777:web:6c1342f9b921814589fe10",
//   measurementId: "G-NYKENM6WXS"
// };
// const clientId = '';
// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// const analytics = app.analytics();
// export const provider = firebase.auth.GoogleAuthProvider;
// export const auth = app.auth();
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import firebase from "firebase";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_bMzoAQaLhnI5PiNOQpEI7OHqU8LdwuQ",
  authDomain: "birimdik-35d2b.firebaseapp.com",
  projectId: "birimdik-35d2b",
  storageBucket: "birimdik-35d2b.appspot.com",
  messagingSenderId: "806199593777",
  appId: "1:806199593777:web:6c1342f9b921814589fe10",
  measurementId: "G-NYKENM6WXS"
};
const clientId = '';
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = app.analytics();
export const provider = firebase.auth.GoogleAuthProvider;
export const auth = app.auth();